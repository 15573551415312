import { useNavigate } from "react-router-dom";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import { Button, Text } from "@mantine/core";

type Props = {
  label?: string;
  route?: string;
};

export default function BackButton({ label, route }: Props) {
  const navigate = useNavigate();
  return (
    <Button
      variant="transparent"
      onClick={() => (route ? navigate(route) : navigate(-1))}
      pl={0}
    >
      <IconArrowNarrowLeft />
      <Text size="sm" ml="sm" c={"black"} fw={500}>
        {label || "Go back"}
      </Text>
    </Button>
  );
}
