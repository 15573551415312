import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {
  Button,
  Flex,
  Group,
  Loader,
  Space,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import {
  IconRouterOff,
  IconZzz,
  IconScan,
  IconTrashX,
} from "@tabler/icons-react";

import TopSection from "../components/TopSection/TopSection";
import { PWAContext } from "../providers/PWAProvider";
import OngoingInstallationDeviceList from "../components/OngoingInstallationDeviceList";
import HalfPanel from "../components/HalfPanel";
import Toast from "../components/Toast";
import { StatusSummaryRow } from "../../components/Status";
import Guide from "../components/Guide";
import useSnoozeProject from "../../data/hooks/Project/useSnoozeProjectHook";
import useUnSnoozeProject from "../../data/hooks/Project/useUnSnoozeProjectHook";
import useProjectDevices from "../../data/hooks/Device/useProjectDevicesHook";
import useUnassignDevicesFromProject from "../../data/hooks/Device/useUnassignDevicesFromProjectHook";
import useProject from "../../data/hooks/Project/useProjectHook";

const SNOOZE_TIME_UNITS = [1, 2, 4, 8, 12];

const EmptyState = () => (
  <Stack align="center">
    <Space h="lg" />
    <IconRouterOff
      size={160}
      stroke={1}
      style={{ transform: "rotate(-90deg)" }}
    />
    <Title size="h2" fw={400}>
      No devices registered
    </Title>
    <Text fw={300} ta="center">
      Scan the QR code on a device to start registering
    </Text>
    <Space h="lg" />
    <Link to="/pwa/scanner">
      <Button size="lg" w="fit-content" justify="center">
        Scan QR codes
      </Button>
    </Link>
  </Stack>
);

export default function Overview() {
  const { instance } = useMsal();
  const { message, setMessage } = useContext(PWAContext);
  const [showMassUnassignConfirm, setShowMassUnassignConfirm] = useState(false);
  const [showSnoozeConfirm, setShowSnoozeConfirm] = useState(false);
  const [snoozeTime, setSnoozeTime] = useState<number | null>(null);
  const { projectId } = useParams<{ projectId: string }>();

  const { mutateAsync: snoozeProject } = useSnoozeProject(instance);
  const { mutateAsync: unsnoozeProject } = useUnSnoozeProject(instance);
  const navigate = useNavigate();
  const { project } = useProject({ instance, workZoneId: projectId ?? "" });

  const { projectDevices, isLoading } = useProjectDevices({
    instance,
    projectIds: [projectId ? projectId : ""],
  });

  const { mutateAsync: unassignAllDevices, isPending } =
    useUnassignDevicesFromProject(instance);

  useEffect(() => {
    if (!projectId) {
      navigate("/pwa/projects");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMassUnassign = async () => {
    if (!project) {
      setMessage({
        title: "Failed to unassign devices",
        text: `Something went wrong when removing all devices from the project`,
        type: "error",
      });
      return;
    }
    setShowMassUnassignConfirm(false);
    unassignAllDevices({ workZoneId: project.id })
      .then(() => {
        setMessage({
          title: "Successfully unassigned all devices",
          text: "All devices are now removed from this project",
          type: "success",
        });
      })
      .catch(() =>
        setMessage({
          title: "Failed to unassign devices",
          text: `Something went wrong when removing all devices from the project ${project?.name}`,
          type: "error",
        }),
      );
  };

  const addHours = (date: Date, hours: number) => {
    const hoursToAdd = hours * 60 * 60 * 1000;
    date.setTime(date.getTime() + hoursToAdd);
    return date;
  };

  const handleSnooze = async () => {
    if (!project || !project.id || !snoozeTime) return;
    snoozeProject({
      id: project?.id,
      snoozedUntil: addHours(new Date(), snoozeTime),
    }).catch(() =>
      setMessage({
        title: "Failed to snooze alarms",
        text: "Something went wrong when trying to snooze the projects alarms",
        type: "error",
      }),
    );
  };

  const handleUnsnooze = async () => {
    if (project && project.id)
      unsnoozeProject({ id: project.id }).catch(() =>
        setMessage({
          title: "Failed to unsnooze project",
          text: "Something went wrong when unsnoozing the project",
          type: "error",
        }),
      );
  };

  const handleSnoozeProject = () => {
    if (!project || !project.id) {
      navigate("/pwa/projects");
      return;
    }

    setShowSnoozeConfirm(false);
    setSnoozeTime(null);

    project.isSnoozed ? handleUnsnooze() : handleSnooze();
  };

  const getDeviceInfo = () => {
    return project && projectDevices[project.id].length === 0 ? (
      <EmptyState />
    ) : (
      <>
        <OngoingInstallationDeviceList
          devices={(project && projectDevices[project.id]) ?? []}
          isSnoozed={project?.isSnoozed}
          countryCode={project?.countryCode ?? ""}
        />

        <Stack mt="md" mb="md" flex={0}>
          <Button
            onClick={() => setShowMassUnassignConfirm(true)}
            size="lg"
            variant="default"
            leftSection={<IconTrashX />}
          >
            Remove all devices
          </Button>
          <Button
            onClick={() => setShowSnoozeConfirm(true)}
            size="lg"
            variant="default"
            leftSection={<IconZzz />}
          >
            {project?.isSnoozed ? "Unsnooze alarms" : "Snooze alarms"}
          </Button>
          <Link to="/pwa/scanner">
            <Button
              size="lg"
              variant="filled"
              w="100%"
              leftSection={<IconScan />}
            >
              Add/Remove device
            </Button>
          </Link>
        </Stack>
      </>
    );
  };

  const getHandleSnoozePanel = () =>
    project?.isSnoozed ? (
      <Text>Are you sure you want to reactivate alarms for this project?</Text>
    ) : (
      <>
        <Text>Select duration of time</Text>
        <Group flex={1} gap={10} mt="sm" w="100%">
          {SNOOZE_TIME_UNITS.map((time) => (
            <Button
              flex={1}
              miw={"fit-content"}
              key={time}
              onClick={() => setSnoozeTime(time)}
              size="lg"
              variant={snoozeTime === time ? "filled" : "default"}
            >
              {time}h
            </Button>
          ))}
        </Group>
      </>
    );

  return (
    <>
      <Guide />
      {message && <Toast message={message} />}

      <TopSection
        hideBackButton
        label="Project"
        showHelp
        title={project?.name}
        rightSection={
          <Link to="/pwa/projects" state={{ prevPath: "overview" }}>
            <Button size="md" variant="default">
              Change
            </Button>
          </Link>
        }
      >
        <StatusSummaryRow
          devices={(project && projectDevices[project.id]) ?? []}
          isSnoozed={project?.isSnoozed}
          snoozedUntil={project?.snoozedUntil}
        />
      </TopSection>

      {isLoading || isPending ? (
        <Flex justify="center">
          <Space h={200} />
          <Loader />
        </Flex>
      ) : (
        getDeviceInfo()
      )}

      <HalfPanel
        confirmColor="red"
        label="Remove all devices from this project"
        open={showMassUnassignConfirm}
        onClose={() => setShowMassUnassignConfirm(!showMassUnassignConfirm)}
        onConfirm={handleMassUnassign}
        title="Unassign all devices"
      >
        <Text>
          Are you sure you want to unassign all devices from this project?
        </Text>
      </HalfPanel>

      <HalfPanel
        confirmDisabled={!project?.isSnoozed && !snoozeTime}
        label={
          project?.isSnoozed
            ? ""
            : "Silence all alarms for a set period of time"
        }
        open={showSnoozeConfirm}
        onClose={() => {
          setShowSnoozeConfirm(!showSnoozeConfirm);
          setSnoozeTime(null);
        }}
        onConfirm={handleSnoozeProject}
        title={project?.isSnoozed ? "Unsnooze project" : "Snooze alarms"}
        confirmLabel={project?.isSnoozed ? "Confirm" : "Activate"}
      >
        {getHandleSnoozePanel()}
      </HalfPanel>
    </>
  );
}
