import { Alert } from "@mantine/core";
import {
  IconCheck,
  IconInfoCircle,
  IconExclamationCircle,
} from "@tabler/icons-react";
import { useContext } from "react";
import { Message, PWAContext } from "../../providers/PWAProvider";

export type ToastType = "success" | "error" | "info";

export type ToastProps = {
  message: Message;
};

export default function Toast({ message }: ToastProps) {
  const { setMessage } = useContext(PWAContext);
  const { indismissable, text, title, type } = message;

  let bg = "blue";
  if (type === "error") bg = "red";
  if (type === "success") bg = "#008A19";

  const getIcon = () => {
    if (type === "error") return <IconExclamationCircle />;
    if (type === "info") return <IconInfoCircle />;
    return <IconCheck />;
  };

  return (
    <Alert
      icon={getIcon()}
      variant="filled"
      color={bg}
      onClose={() => setMessage(null)}
      title={title}
      withCloseButton={!indismissable}
      pos="fixed"
      top={75}
      right={20}
      left={20}
      style={{ boxShadow: "rgba(0, 0, 0, 0.3) 2px 3px 5px 0px", zIndex: 2 }}
    >
      {text}
    </Alert>
  );
}
