import { Navigate, useLocation } from "react-router-dom";
import Root from "./routes/Root";
import Home from "./routes/Home";
import DevicesPage from "./routes/DeviceListPage";
import Map from "./routes/Map";
import Error from "./routes/Error";
import Login from "./routes/Login";
import { EditDevicePage } from "./routes/EditDevicePage";
import { BulkProvisionDevices } from "./shared/BulkProvisionDevices";
import Projects from "./pwa/routes/Projects";
import Pair from "./pwa/routes/Pair";
import Summary from "./pwa/routes/Summary";
import Disconnect from "./pwa/routes/Disconnect";
import Help from "./pwa/routes/Help";
import Redirect from "./routes/Redirect";
import Overview from "./pwa/routes/Overview";
import Scanner from "./pwa/routes/Scanner";
import AccessManagement from "./routes/AccessManagement";
import { ErrorBoundary } from "./components/Error/ErrorBoundary";
import ProjectPage from "./routes/ProjectPage";
import BatteryBlockerScreen from "./pwa/routes/BatteryBlockerScreen";
import { BatteryStatus } from "./models/enums/DeviceEnums";
import NotAvailableForAssignmentBlockerScreen from "./pwa/routes/NotAvailableForAssignmentBlockerScreen";
import PwaWrapper from "./pwa/components/PwaWrapper";
import { AfterLoginRedirect } from "./routes/AfterLogin";
import { AccessDenied } from "./routes/AccessDenied";

const ErrorBoundaryWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();

  return <ErrorBoundary key={location.pathname}>{children}</ErrorBoundary>;
};

export const protectedRoutes = [
  {
    path: "/after-login",
    element: <AfterLoginRedirect />,
  },
  {
    path: "/",
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Redirect />,
      },
      {
        path: "/access-denied",
        element: <AccessDenied />,
      },
      {
        path: "/projects",
        element: (
          <ErrorBoundaryWrapper>
            <Home />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: "/map",
        element: (
          <ErrorBoundaryWrapper>
            <Map />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: "/devices",
        element: (
          <ErrorBoundaryWrapper>
            <DevicesPage />
          </ErrorBoundaryWrapper>
        ),
        children: [],
      },
      {
        path: "/devices/edit/:deviceId",
        element: (
          <ErrorBoundaryWrapper>
            <EditDevicePage />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: "/bulk-provision-devices",
        element: (
          <ErrorBoundaryWrapper>
            <BulkProvisionDevices />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: "/projects/:projectId",
        element: (
          <ErrorBoundaryWrapper>
            <ProjectPage />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: "/access-management",
        element: <AccessManagement />,
      },
      {
        path: "/redirect",
        element: <Redirect />,
      },
      {
        path: "*",
        element: (
          <Navigate to="/redirect" state={{ from: window.location.pathname }} />
        ),
      },
    ],
  },
  {
    path: "/pwa/projects/:projectId",
    element: (
      <PwaWrapper>
        <Overview />
      </PwaWrapper>
    ),
  },
  {
    path: "/pwa/projects",
    element: <Projects />,
  },
  {
    path: "/pwa/pair",
    element: <Pair />,
  },
  {
    path: "/pwa/disconnect",
    element: <Disconnect />,
  },
  {
    path: "/pwa/summary",
    element: <Summary />,
  },
  {
    path: "/pwa/critical-battery",
    element: <BatteryBlockerScreen type={BatteryStatus.CRITICAL} />,
  },
  {
    path: "/pwa/out-of-battery",
    element: <BatteryBlockerScreen type={BatteryStatus.OUT_OF_BATTERY} />,
  },
  {
    path: "/pwa/not-available-for-assignment",
    element: <NotAvailableForAssignmentBlockerScreen />,
  },
  {
    path: "/pwa/help",
    element: <Help />,
  },
  {
    path: "/pwa/scanner",
    element: <Scanner />,
  },
  {
    path: "/pwa",
    element: <Navigate to="/pwa/projects" />,
  },
  {
    path: "*",
    element: <Navigate to="/redirect" />,
  },
];

export const openRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/redirect",
    element: <Redirect />,
  },
  {
    path: "*",
    element: (
      <Navigate to="/redirect" state={{ from: window.location.pathname }} />
    ),
  },
];
