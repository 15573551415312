import { useContext, useEffect } from "react";
import { Container, Stack } from "@mantine/core";
import TopSection from "../components/TopSection/TopSection.tsx";
import BottomSection from "../components/BottomSection";
import { PWAContext } from "../providers/PWAProvider";
import Row from "../components/Row";
import { useDeviceConnection } from "../hooks/useDeviceConnection";
import styles from "./styles.module.css";
import { EquipmentType } from "../../models/enums/DeviceEnums";
import { getSignsByCountry } from "../../config/roadsigns.ts";

export interface Sign {
  name: string;
  value?: string;
}

export default function Disconnect() {
  const { disconnect } = useDeviceConnection();
  const { device, project } = useContext(PWAContext);
  const countrySigns = getSignsByCountry(project?.countryCode ?? "");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className={styles.pwaRoot} flex={1} px={20} w="100vw" h="100vh">
      <TopSection title="Unassign device" />

      <Stack p={30} bg="#e2f0f099" style={{ borderRadius: 4 }} w={"100%"}>
        <Row label="Project" text={device?.workZoneName || "-"} />
        <Row label="Name" text={device?.currentName || "-"} />
        {device?.currentEquipmentType === EquipmentType.Sign ? (
          <Row
            sign={device?.attachmentRef}
            label="Sign"
            text={
              Object.keys(countrySigns)[
                Object.values(countrySigns).indexOf(device?.attachmentRef)
              ]
            }
          />
        ) : (
          <Row label="Equipment type" text="Barrier" />
        )}
        <Row label="Reference id" text={device?.referenceId || "-"} />
      </Stack>

      <BottomSection
        disabled={!device}
        gradient
        handleClick={() => device && disconnect(device)}
        label="Unassign"
      />
    </Container>
  );
}
