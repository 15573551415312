import { Group, Image, Stack, Text, Title } from "@mantine/core";
import { getImageUrlForSign } from "../../../utils";

type Props = {
  badge?: React.ReactElement;
  sign?: string;
  label: string;
  text: string;
};

export default function Row({ badge, sign, label, text }: Props) {
  return (
    <Stack style={{ borderBottom: "2px solid rgba(255, 255, 255, 1)" }} pb={10}>
      <Text c="gray" flex={2}>
        {label}
      </Text>
      <Group flex={3} mt={-15}>
        <Title order={2} size="h4">
          {text}
        </Title>
        {sign && <Image w={30} src={getImageUrlForSign(sign)} />}
        {badge}
      </Group>
    </Stack>
  );
}
