import { useMobileDevice } from "../../hooks/useMobileDevice.tsx";
import {
  Button,
  CloseButton,
  Divider,
  Group,
  InputBase,
  InputWrapper,
  Modal,
  Paper,
  ScrollArea,
  SegmentedControl,
  Space,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Device } from "../../models/Device.ts";
import { EquipmentType } from "../../models/enums/DeviceEnums.ts";
import useAssignDeviceToProject from "../../data/hooks/Device/useAssignDeviceToProjectHook.ts";
import { getSignsByCountry } from "../../config/roadsigns.ts";
import SignList from "../../pwa/components/SignList";
import { Sign } from "../../pwa/routes/Pair.tsx";
import { PopoverConfirm } from "../Popover/PopoverConfirm.tsx";

interface Props {
  device: Device;
  countryCode: string;
  showModal: boolean;
  close: () => void;
  onSubmit: () => void;
}

export const EditDeviceInformationModal = ({
  device,
  countryCode,
  showModal,
  close,
}: Props) => {
  const { instance } = useMsal();
  const isMobile = useMobileDevice();
  const [equipmentType, setEquipmentType] = useState<EquipmentType>(
    device.currentEquipmentType ?? EquipmentType.Barrier,
  );
  const [selectedSign, setSelectedSign] = useState<Sign | null>(null);
  const [additionalInformation, setAdditionalInformation] = useState<
    string | null
  >(null);
  const [openedUnassignPopover, setOpenedUnassignPopover] =
    useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>("");

  const { mutateAsync: mutate, isPending: deviceAssignPending } =
    useAssignDeviceToProject(instance);

  const getName = () => {
    if (additionalInformation && additionalInformation.length > 0) {
      return additionalInformation;
    } else {
      return device.currentName;
    }
  };

  const handleEditDeviceInformation = () => {
    const selectedRoadSign: string | null =
      getSignsByCountry(countryCode)[selectedSign?.name ?? ""] ?? null;

    mutate({
      workZoneId: device.workZoneId,
      deviceId: device.id,
      equipmentType,
      currentName: getName(),
      attachmentRef: selectedRoadSign,
    }).then(() => close());
  };

  const handleUnassignDevice = () => {
    mutate({
      workZoneId: null,
      deviceId: device.id,
      equipmentType: null,
      currentName: null,
      attachmentRef: null,
    }).then(() => close());
  };

  return (
    <Modal.Root
      opened={showModal}
      onClose={close}
      fullScreen={isMobile}
      size="900px"
      centered
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title fw={600}>Edit Device Information</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Group gap={"xl"} align="top">
            <div style={{ minWidth: "50%", width: "100%" }}>
              <div style={{ marginTop: "8px" }}>
                <Paper p={"md"} shadow="xs">
                  <Title order={4} ta={"left"} mb={"lg"}>
                    {device.referenceId}
                  </Title>
                  <Stack gap={"lg"}>
                    <TextInput
                      label="Project"
                      value={device.workZoneName}
                      disabled
                      readOnly
                    />
                    <InputWrapper
                      ta={"left"}
                      label="Additional Information"
                      description="Information regarding placement or name of device (optional)"
                    >
                      <Textarea
                        value={additionalInformation ?? undefined}
                        onChange={(event) =>
                          setAdditionalInformation(event.currentTarget.value)
                        }
                        minRows={3}
                        autosize
                      />
                    </InputWrapper>
                  </Stack>
                </Paper>
              </div>
            </div>
            <div style={{ flexGrow: "1" }}>
              <Group grow>
                <SegmentedControl
                  value={equipmentType.toString()}
                  onChange={(type) => {
                    if (type === EquipmentType.Sign.toString()) {
                      setEquipmentType(EquipmentType.Sign);
                    } else {
                      setEquipmentType(EquipmentType.Barrier);
                      setSelectedSign(null);
                    }
                  }}
                  fullWidth
                  data={[
                    {
                      label: "Sign",
                      value: EquipmentType.Sign.toString(),
                    },
                    {
                      label: "Barrier",
                      value: EquipmentType.Barrier.toString(),
                    },
                  ]}
                />
              </Group>
            </div>
          </Group>
          <Group wrap={isMobile ? "wrap" : "nowrap"} align="top"></Group>
          <Divider mt={"md"} />
          <Space h="md" />
          {equipmentType === EquipmentType.Sign && (
            <>
              <Text c="gray.6" size="xs">
                {" "}
                Please select a sign type. Search or browse below.
              </Text>
              <Group gap={10}>
                <InputBase
                  size="md"
                  rightSection={
                    searchString !== "" && (
                      <CloseButton
                        size="sm"
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={() => setSearchString("")}
                        aria-label="Clear value"
                      />
                    )
                  }
                  value={searchString}
                  onChange={(event) =>
                    setSearchString(event.currentTarget.value)
                  }
                  flex={1}
                  placeholder="Search sign"
                />
              </Group>

              <ScrollArea h={"20vh"}>
                <SignList
                  handleClick={setSelectedSign}
                  selected={selectedSign}
                  searchString={searchString}
                  countryCode={countryCode}
                />
              </ScrollArea>
            </>
          )}
          <Divider my="sm" />
          <Group p="center">
            <PopoverConfirm
              opened={openedUnassignPopover}
              setOpened={(next) =>
                setOpenedUnassignPopover(next)
              }
              text={`Are you sure you want to disconnect device ${device.currentName} from the project?`}
              btnText={`Unassign Device`}
              width={320}
              onClick={handleUnassignDevice}
            />
          </Group>
          <Divider my="sm" />
          <Group gap={"xs"} justify="end">
            <Button variant="outline" onClick={close} mr="sm" mt="10px">
              Cancel
            </Button>
            <Button
              onClick={handleEditDeviceInformation}
              mr="sm"
              mt="10px"
              disabled={deviceAssignPending}
            >
              Save
            </Button>
          </Group>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
