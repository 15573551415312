import { useContext, useEffect, useState } from "react";
import {
  Container,
  CloseButton,
  InputBase,
  Group,
  LoadingOverlay,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";

import TopSection from "../components/TopSection/TopSection";
import BottomSection from "../components/BottomSection";
import SignList from "../components/SignList";
import { PWAContext } from "../providers/PWAProvider";
import styles from "./styles.module.css";
import SignRecognition from "../components/SignRecognition";
import Toast from "../components/Toast";
import SignRecognitionModal from "../components/SignRecognition/SignRecognitionModal";

export interface Sign {
  name: string;
  value?: string;
}

export default function Pair() {
  const { device, message, sign, setSign, project } = useContext(PWAContext);
  const [searchString, setSearchString] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [identifiedSign, setIdentifiedSign] = useState<Sign | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nextStep = () => navigate("/pwa/summary");

  const handleSignIdentified = (sign: Sign) => {
    setShowModal(true);
    setIdentifiedSign(sign);
  };

  const handleConfirm = () => {
    setSign(identifiedSign);
    setShowModal(false);
    nextStep();
  };

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      {message && <Toast message={message} />}

      <Container className={styles.pwaRoot} px={20} w="100vw" pos="relative">
        <TopSection
          label={`Reference id: ${device?.referenceId || "-"}`}
          title="Select sign"
        >
          <Group gap={10}>
            <div
              style={{ flex: 1 }}
              onClick={() => {
                if (window._paq) {
                  window._paq.push([
                    "trackEvent",
                    "Pair device",
                    "Search clicked",
                  ]);
                }
              }}
            >
              <InputBase
                size="md"
                rightSection={
                  searchString !== "" && (
                    <CloseButton
                      size="sm"
                      onMouseDown={(event) => event.preventDefault()}
                      onClick={() => {
                        setSearchString("");
                      }}
                      aria-label="Clear value"
                    />
                  )
                }
                value={searchString}
                onChange={(event) => setSearchString(event.currentTarget.value)}
                flex={1}
                placeholder="Search sign"
              />
            </div>
            <div
              onClick={() => {
                if (window._paq) {
                  window._paq.push([
                    "trackEvent",
                    "Pair device",
                    "AI Button Clicked",
                    device?.referenceId,
                  ]);
                }
              }}
            >
              <SignRecognition
                isLoading={isLoading}
                onImageRecognized={handleSignIdentified}
                setIsLoading={setIsLoading}
              />
            </div>
          </Group>
        </TopSection>

        <SignList
          handleClick={setSign}
          selected={sign}
          searchString={searchString}
          countryCode={project?.countryCode ?? ""}
        />
        <BottomSection
          disabled={!sign || showModal}
          gradient
          label={`Continue ${sign?.name ? ` with ${sign?.name} ` : ""}`}
          handleClick={nextStep}
        />
      </Container>

      <SignRecognitionModal
        identifiedSign={identifiedSign}
        onConfirm={handleConfirm}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
}
