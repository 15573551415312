import { useEffect, useState } from "react";
import { IconRouterOff } from "@tabler/icons-react";
import { Group, Space, Stack, Switch, Text, Title } from "@mantine/core";
import { DeviceListItemLight } from "../../components/Device/DeviceListItem/DeviceListItemLight.tsx";
import { Device } from "../../models/Device.ts";
import { sortDevices } from "../../services/helpers.tsx";

type Props = {
  devices: Device[];
  isSnoozed?: boolean;
  countryCode: string;
};

const RECENTLY_ADDED_HOUR_THRESHOLD = 4;

export default function OngoingInstallationDeviceList({
  devices,
  isSnoozed,
  countryCode,
}: Props) {
  const [recent, setRecent] = useState<Device[]>([]);
  const [showRecent, setShowRecent] = useState(false);

  const filterRecent = () => {
    const now = new Date();
    const recently = new Date().setHours(
      now.getHours() - RECENTLY_ADDED_HOUR_THRESHOLD,
    );

    const added = devices.filter(
      (device) => new Date(device.assignedAtTimestamp) > new Date(recently),
    );

    setRecent(added);
  };

  useEffect(() => {
    filterRecent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices]);

  const listDevices = () => {
    const items = showRecent ? recent : devices;
    if (items.length === 0)
      return (
        <Stack align="center">
          <Space h="50px" />
          <IconRouterOff
            opacity={0.25}
            size="75px"
            stroke={1.25}
            style={{ transform: "rotate(-90deg)" }}
          />
          <Title opacity={0.5} fw={400} order={4} ta="center">
            {showRecent
              ? `No devices assigned the last ${RECENTLY_ADDED_HOUR_THRESHOLD} hours`
              : "No devices found"}
          </Title>
        </Stack>
      );

    return sortDevices(items).map((device) => (
      <DeviceListItemLight
        key={device.id}
        device={device}
        isSnoozed={isSnoozed}
        countryCode={countryCode}
        onClick={() => {}}
      />
    ));
  };

  return (
    <Stack flex={1} style={{ overflow: "auto" }}>
      <Stack>
        <Group justify="space-between">
          <Title fw={400} order={4}>
            Devices
          </Title>
          <Group gap={4}>
            <Text size="lg" fw={600}>
              {devices.length}
            </Text>
            <Text fw={200}>assigned ({recent.length} new)</Text>
          </Group>
        </Group>
        <Group justify="flex-end">
          <Switch
            c="gray"
            checked={showRecent}
            onChange={(event) => setShowRecent(event.currentTarget.checked)}
            label={`Only show assigned last ${RECENTLY_ADDED_HOUR_THRESHOLD} hours`}
          />
        </Group>
      </Stack>

      <Stack mt="sm" gap="sm" style={{ overflow: "scroll" }}>
        {listDevices()}
      </Stack>
    </Stack>
  );
}
