import { Container, Stack, Text, Title } from "@mantine/core";
import { IconBattery, IconBattery1 } from "@tabler/icons-react";

import TopSection from "../components/TopSection/TopSection";
import { BatteryStatus } from "../../models/enums/DeviceEnums";

type Props = {
  type: BatteryStatus.CRITICAL | BatteryStatus.OUT_OF_BATTERY;
};

export default function BatteryBlockerScreen({ type }: Props) {
  if (type == BatteryStatus.CRITICAL) {
    return (
      <div style={{ backgroundColor: "#fa5252" }}>
        <Container bg="red" h="100vh" pt="xl">
          <TopSection />
          <Stack align="center" justify="center" h="70%">
            <IconBattery1 color="white" size={150} stroke={1.2} />
            <Title style={{ textAlign: "center" }} c="white">
              Battery status: Critical
            </Title>
            <Text c="white">Select a different device</Text>
          </Stack>
        </Container>
      </div>
    );
  }
  return (
    <div style={{ backgroundColor: "#dddddd" }}>
      <Container bg="#dddddd" h="100vh" pt="xl">
        <TopSection />
        <Stack align="center" justify="center" h="70%">
          <IconBattery color="black" size={150} stroke={1.2} />
          <Title style={{ textAlign: "center" }} c="black">
            Battery status: Out of battery
          </Title>
          <Text c="black">Select a different device</Text>
        </Stack>
      </Container>
    </div>
  );
}
