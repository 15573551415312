import { useMsal } from "@azure/msal-react";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Divider,
  Group,
  Space,
  Stack,
  Switch,
  Text,
} from "@mantine/core";

import BarCodeScanner from "../components/Scanner/BarCodeScanner";
import ManualConnection from "../components/Scanner/ManualConnection";
import TopSection from "../components/TopSection/TopSection";
import { PWAContext } from "../providers/PWAProvider";
import Toast from "../components/Toast";
import EquipmentTypeSelector from "../components/EquipmentTypeSelector";
import styles from "./styles.module.css";
import { StatusSummaryRow } from "../../components/Status";
import useProjectDevices from "../../data/hooks/Device/useProjectDevicesHook";

export default function Scanner() {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { automaticDisconnect, message, setAutomaticDisconnect, project } =
    useContext(PWAContext);

  const { projectDevices } = useProjectDevices({
    instance,
    projectIds: [project ? project.id : ""],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!project) navigate("/pwa/projects");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      className={styles.pwaRoot}
      px={20}
      h="100vh"
      w="100vw"
      pos="relative"
    >
      {message && <Toast message={message} />}
      <Stack h="100vh">
        <TopSection
          backButtonLabel="Back to project overview"
          backButtonRoute={`/pwa/projects/${project?.id}`}
          label="Project:"
          placeholder="No project selected"
          title={project?.name}
          showHelp={true}
          rightSection={
            projectDevices &&
            project && (
              <Group gap={4}>
                <Text size="lg" fw={600}>
                  {projectDevices[project.id].length}
                </Text>
                <Text fw={200}>assigned</Text>
              </Group>
            )
          }
        >
          <StatusSummaryRow
            devices={(project && projectDevices[project.id]) || []}
            isSnoozed={project?.isSnoozed}
            snoozedUntil={project?.snoozedUntil}
          />
        </TopSection>
        <Divider mt="sm" mb="sm" size="xs" />
        <EquipmentTypeSelector />

        <BarCodeScanner />

        <Divider label="or" labelPosition="center" mt="sm" size="sm" />
        <ManualConnection />
        <Space />
        <Switch
          checked={automaticDisconnect}
          label="Unassign automatically mode"
          onChange={(event) =>
            setAutomaticDisconnect(event.currentTarget.checked)
          }
          size="md"
        />
        <Space h="lg" />
      </Stack>
    </Container>
  );
}
