import { ReactNode } from "react";
import { IconHelp, IconLogout, IconMenu2 } from "@tabler/icons-react";
import {
  ActionIcon,
  Group,
  Image,
  Menu,
  Stack,
  Text,
  Title,
} from "@mantine/core";

import BackButton from "../BackButton";
import { getPWAStatus } from "../../utils/platform";
import logo from "../../../assets/ramudden_logo.svg";
import { useAuthUtils } from "../../../shared/authUtils.tsx";

type Props = {
  backButtonRoute?: string;
  backButtonLabel?: string;
  label?: string;
  title?: string;
  children?: ReactNode;
  link?: string;
  linkLabel?: string;
  hideBackButton?: boolean;
  placeholder?: string;
  showHelp?: boolean;
  rightSection?: React.ReactNode;
};

export default function TopSection({
  backButtonLabel,
  backButtonRoute,
  children,
  hideBackButton,
  label,
  title,
  placeholder,
  showHelp,
  rightSection,
}: Props) {
  const { handleLogout } = useAuthUtils();
  
  return (
    <Stack
      pt={getPWAStatus() === "standalone" ? 0 : "md"}
      w="100%"
      mb="md"
      flex={0}
    >
      <Group h="40px" justify="space-between">
        {!hideBackButton ? (
          <BackButton label={backButtonLabel} route={backButtonRoute} />
        ) : (
          <Image src={logo} maw="160px" ml="-13px" />
        )}

        <Menu shadow="md" width={200} position={"bottom-end"}>
          <Menu.Target>
            <ActionIcon variant="transparent" color="black">
              <IconMenu2 size={"36px"} />
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown p={"sm"}>
            {showHelp && (
              <Menu.Item
                component="a"
                href="/pwa/help"
                leftSection={<IconHelp size="24px" />}
                mb={"xs"}
              >
                <Text ml={"xs"} size="lg">
                  Help
                </Text>
              </Menu.Item>
            )}
            <Menu.Item
              leftSection={<IconLogout size="24px" />}
              onClick={handleLogout}
            >
              <Text ml={"xs"} size="lg">
                Logout
              </Text>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
      <Group justify="space-between">
        <div style={{ flex: 4 }}>
          <Text>{label}</Text>
          <Title order={1} size="h3" c={title ? "" : "rgba(0, 0, 0, 0.3)"}>
            {title || placeholder}
          </Title>
        </div>
        {rightSection}
      </Group>
      {children}
    </Stack>
  );
}
