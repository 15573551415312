import { Container } from "@mantine/core";
import styles from "../routes/styles.module.css";
import { ReactNode } from "react";

export default function PwaWrapper({ children }: { children: ReactNode }) {
  return (
    <Container
      className={styles.pwaRoot}
      px={20}
      h="100vh"
      w="100vw"
      display="flex"
      style={{ flexDirection: "column" }}
    >
      {children}
    </Container>
  );
}
