import { Container, Stack, Text, Title } from "@mantine/core";
import TopSection from "../components/TopSection/TopSection";
import { IconExclamationCircle } from "@tabler/icons-react";

export default function NotAvailableForAssignmentBlockerScreen() {
  return (
    <div style={{ backgroundColor: "#dddddd" }}>
      <Container bg="#dddddd" h="100vh" pt="xl">
        <TopSection />
        <Stack align="center" justify="center" h="70%">
          <IconExclamationCircle color="black" size={150} stroke={1.2} />
          <Title style={{ textAlign: "center" }} c="black">
            Device is not available for assignment.
          </Title>
          <Text c="black">Select a different device</Text>
        </Stack>
      </Container>
    </div>
  );
}
