import { useEffect } from "react";
import { Accordion, Container, Group, Space, Text, Title } from "@mantine/core";
import {
  IconBrandAndroid,
  IconBrandAppleFilled,
  IconBrandChrome,
  IconBrandFirefox,
  IconBrandSafari,
  IconCamera,
  IconDotsVertical,
  IconMapPin,
  IconMenu2,
  IconShare2,
  IconSquarePlus,
  IconUpload,
} from "@tabler/icons-react";

import TopSection from "../components/TopSection/TopSection";
import styles from "./styles.module.css";
import { getPWAStatus } from "../utils/platform";

export interface Sign {
  name: string;
  value?: string;
}

const ios = [
  {
    emoji: <IconBrandSafari color="var(--mantine-color-deepGreen-7)" />,
    value: "Save to Home Screen - Safari",
    description: (
      <Text>
        Press the <IconShare2 /> icon in the menu bar in the bottom of the
        screen. Find and click the Add to Home Screen <IconSquarePlus /> option.
        In the following screen, click Add and the app will be saved to your
        Home Screen and you can find the Ramudden app amongst your other apps.
      </Text>
    ),
  },
  {
    emoji: <IconBrandChrome color="var(--mantine-color-deepGreen-7)" />,
    value: "Save to Home Screen - Chrome",
    description: (
      <Text>
        Press the <IconShare2 /> in the address bar located in the top right of
        the screen. Find and click the Add to Home Screen <IconSquarePlus />{" "}
        option. In the following screen, click Add and the app will be saved to
        your Home Screen and you can find the Ramudden app amongst your other
        apps.
      </Text>
    ),
  },
  {
    emoji: <IconBrandFirefox color="var(--mantine-color-deepGreen-7)" />,
    value: "Save to Home Screen - Firefox",
    description: (
      <Text>
        Press the <IconMenu2 /> icon in the bottom tab bar. Find and press Share{" "}
        <IconUpload /> and then select the Add to Home Screen <IconSquarePlus />{" "}
        option. In the following screen, click Add and the app will be saved to
        your Home Screen and you can find the Ramudden app amongst your other
        apps.
      </Text>
    ),
  },
];

const generalIos = [
  {
    emoji: <IconCamera color="var(--mantine-color-deepGreen-7)" />,
    value: "Camera permissions",
    description: (
      <Text>
        By default, browsers on iOS asks for camera permissions everytime the
        camera launches in the browser (or webapp saved to home screen). In
        Safari, this can be prevented by setting the camera permissions to Allow
        by default. To change this, open the Settings app, scroll down to and
        click Safari {`>`} Camera and select Allow.
      </Text>
    ),
  },
  {
    emoji: <IconMapPin color="var(--mantine-color-deepGreen-7)" />,
    value: "Location permissions - Safari",
    description: (
      <Text>
        Some features, like finding the closest project, are using your current
        position. By default, the app asks for permission the first time
        accessing your location. However, if this has already been denied, you
        need to manually update this in the device Settings. You need to make
        sure these are active in two places.
        <br />- Go to Settings {`>`} Privacy & Security {`>`} Location Services{" "}
        {`>`} Safari Websites {`>`} While Using the App
        <br />- Settings {`>`} Safari {`>`} Location {`>`} Allow
      </Text>
    ),
  },
];

const android = [
  {
    emoji: <IconBrandChrome color="var(--mantine-color-deepGreen-7)" />,
    value: "Save to Home Screen - Chrome",
    description: (
      <Text>
        Press the <IconDotsVertical /> in the top right corner in the browser.
        Find and click the Add to Home screen. In the bottom sheet (Add to home
        screen), select Install and confirm by clicking Install in the prompt.
        You will get a notification saying when the app is Installed. You will
        then find the Ramudden app amongst your other apps.
      </Text>
    ),
  },
  {
    emoji: <IconBrandFirefox color="var(--mantine-color-deepGreen-7)" />,
    value: "Save to Home Screen - Firefox",
    description: (
      <Text>
        Press the <IconDotsVertical /> in the top right corner in the browser.
        Find and click the Add to Home screen. In the bottom sheet - Add to home
        screen? - select Add and it will add the Ramudden app to your Home
        screen.
      </Text>
    ),
  },
];

export default function Help() {
  const pwaStatus = getPWAStatus();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getIosHelp = () => {
    let faq = generalIos;
    if (pwaStatus !== "standalone") faq = faq.concat(ios);

    return faq.map((item) => (
      <Accordion.Item key={item.value} value={item.value}>
        <Accordion.Control icon={item.emoji}>{item.value}</Accordion.Control>
        <Accordion.Panel>{item.description}</Accordion.Panel>
      </Accordion.Item>
    ));
  };

  const getAndroidHelp = () => {
    if (pwaStatus === "standalone") return null;
    return android.map((item) => (
      <Accordion.Item key={item.value} value={item.value}>
        <Accordion.Control icon={item.emoji}>{item.value}</Accordion.Control>
        <Accordion.Panel>{item.description}</Accordion.Panel>
      </Accordion.Item>
    ));
  };

  return (
    <Container className={styles.pwaRoot} px={20} w="100vw" pos="relative">
      <TopSection label="Help" title="Common questions" />
      <Group>
        <IconBrandAppleFilled
          fill="var(--mantine-color-deepGreen-8)"
          color="var(--mantine-color-deepGreen-8)"
        />
        <Title order={2} size="h4">
          iOS
        </Title>
      </Group>
      <Space h="md" />
      <Accordion chevronPosition="left" variant="filled">
        {getIosHelp()}
      </Accordion>
      {pwaStatus !== "standalone" && (
        <>
          <Space h="xl" />
          <Group>
            <IconBrandAndroid />
            <Title order={2} size="h4">
              Android
            </Title>
          </Group>
          <Space h="md" />
          <Accordion chevronPosition="left" variant="filled">
            {getAndroidHelp()}
          </Accordion>
        </>
      )}
    </Container>
  );
}
