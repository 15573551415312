import { Badge, Group, Text } from "@mantine/core";
import { IconBattery1, IconBattery2, IconBattery4 } from "@tabler/icons-react";
import styles from "../styles.module.css";
import { BatteryStatus } from "../../../models/enums/DeviceEnums";

const BatteryStatusNames = {
  [BatteryStatus.OUT_OF_BATTERY]: "Out of battery",
  [BatteryStatus.CRITICAL]: "Critical",
  [BatteryStatus.LOW]: "Low",
  [BatteryStatus.OK]: "OK",
};

type Props = {
  batteryStatus: BatteryStatus | null;
  number?: number;
  size?: "md" | "lg";
};

export default function BatteryBadge({
  batteryStatus,
  number,
  size = "lg",
}: Props) {
  if (!batteryStatus) return null;

  const getBatteryIcon = () => {
    if (batteryStatus === BatteryStatus.CRITICAL)
      return <IconBattery1 size={"16px"} color="black" stroke={1.3} />;
    if (batteryStatus === BatteryStatus.LOW)
      return <IconBattery2 size={"16px"} color="black" stroke={1.3} />;
    if (batteryStatus === BatteryStatus.OUT_OF_BATTERY) return null;
    return <IconBattery4 size={"16px"} color="black" stroke={1.3} />;
  };

  return (
    <Badge
      leftSection={getBatteryIcon()}
      size={size ? size : "lg"}
      className={styles[BatteryStatus[batteryStatus]]}
    >
      <Group gap={6}>
        <Text size={size == "md" ? "xs" : "sm"} c="black" fw={500}>
          {number} {BatteryStatusNames[batteryStatus]}
        </Text>
      </Group>
    </Badge>
  );
}
