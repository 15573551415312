import { useContext, useEffect, useState } from "react";
import { Container, Space, Stack, Textarea } from "@mantine/core";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

import { PWAContext } from "../providers/PWAProvider";
import TopSection from "../components/TopSection/TopSection";
import BottomSection from "../components/BottomSection";
import { setDeviceWorkzoneId } from "../../services/devices";
import Row from "../components/Row";
import styles from "./styles.module.css";
import { BatteryBadge } from "../../components/Badge";
import DeviceActivationInfo from "../components/DeviceActivationInfo";
import { EquipmentType } from "../../models/enums/DeviceEnums";
import { getDeviceInfoDescription, getDeviceInfoLabel } from "../../copy";

export default function Summary() {
  const { instance } = useMsal();
  const {
    device,
    equipmentType,
    sign,
    project,
    setMessage,
    setDevice,
    setSign,
  } = useContext(PWAContext);
  const [currentName, setCurrentName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const connect = () => {
    if (
      project?.id &&
      device?.id &&
      (equipmentType === EquipmentType.Barrier || sign?.value)
    ) {
      setDeviceWorkzoneId(
        instance,
        project?.id,
        device?.id,
        equipmentType,
        currentName,
        sign?.value || null,
      )
        .then(() => {
          setDevice(null);
          setSign(null);
          setMessage({
            text: `Successfully paired device with ${equipmentType === EquipmentType.Barrier ? "barrier" : sign?.name}`,
            title: "Successfully assigned device",
            type: "success",
          });
          navigate("/pwa/scanner");
        })
        .catch((error) => {
          setMessage({
            text: error,
            title: "Something went wrong",
            type: "error",
          });
        });
    }
  };

  return (
    <Container className={styles.pwaRoot} px={20} h="100vh" w="100vw">
      <TopSection placeholder="" title="Summary" />

      <Textarea
        label={getDeviceInfoLabel(project?.countryCode || "")}
        placeholder={getDeviceInfoDescription(project?.countryCode || "")}
        onChange={(event) => setCurrentName(event.currentTarget.value)}
        size="md"
        value={currentName}
      />
      <Space h="md" />
      <DeviceActivationInfo />
      <Space h="md" />
      <Stack bg="#e2f0f099" style={{ borderRadius: 4 }} p={30}>
        <Row label="Project" text={project?.name || "-"} />
        {equipmentType === EquipmentType.Sign && (
          <Row sign={sign?.value} label="Sign" text={sign?.name || "-"} />
        )}
        <Row label="Reference id" text={device?.referenceId || "-"} />
        <Row
          badge={
            <BatteryBadge
              batteryStatus={device ? device.batteryStatus : null}
            />
          }
          label="Battery status"
          text={`${device?.batteryVoltage || "- "}V`}
        />
        <Row label="Equipment type" text={EquipmentType[equipmentType]} />
      </Stack>
      <Space h={120} />
      <BottomSection gradient handleClick={connect} label="Assign" />
    </Container>
  );
}
