import { createTheme} from "@mantine/core";

export default createTheme({
    primaryColor: "primaryGreen",
    colors: {
      primaryGreen: [
        '#fff',
        '#fff',
        '#fff',
        '#fff',
        '#fff',
        '#398582',
        '#2E6B69',
        '#285958',
        '#fff',
        '#fff',
      ],
      deepGreen: [
        "#e8ebeb",
        "#d1d6d6",
        "#b9c2c2",
        "#a2adad",
        "#8b9999",
        "#748484",
        "#5d7070",
        "#455b5b",
        "#2e4747",
        "#173232",
      ],
      mainYellow: [
        "#fffce0",
        "#fff8ca",
        "#fff099",
        "#ffe862",
        "#ffe136",
        "#ffdc18",
        "#ffda00",
        "#e3c100",
        "#c9ab00",
        "#ae9300",
      ],
      sand: [
        "#fffaeb",
        "#fffbed",
        "#fffbef",
        "#fffcf1",
        "#fffcf3",
        "#fffdf5",
        "#fffdf7",
        "#fffef9",
        "#fffefb",
        "#fffffd",
      ],
      sandBg: [
        "#f8f6f1",
        "#edebe4",
        "#dbd4c5",
        "#c8bda1",
        "#b9a984",
        "#af9c70",
        "#ab9664",
        "#958254",
        "#857348",
        "#74633a"
      ]
    },
    spacing: {
      none: "0px",
      xs: "4px",
      sm: "8px",
      md: "16px",
      lg: "24px",
      xl: "32px",
    },
    headings: {
      
      sizes: {
        h4: {
          fontWeight: '300'

        },
        h5: {
          fontWeight: '200'
        }
      }
    }
    
  });