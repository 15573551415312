
export enum BatteryStatus {
    OK = 1,
    LOW = 2,
    CRITICAL = 3,
    OUT_OF_BATTERY = 4,
}

export const BatteryStatusNames = {
    [BatteryStatus.OK]: 'OK',
    [BatteryStatus.LOW]: 'LOW',
    [BatteryStatus.CRITICAL]: 'CRITICAL',
    [BatteryStatus.OUT_OF_BATTERY]: 'OUT_OF_BATTERY',
}

export enum AlarmStatus {
    OK = 1,
    Alarming = 2,
}

export enum LifeCycleStatus {
    Unassigned = 1,
    Assigned = 2,
    Deprovisioned = 3
}


export const AlarmStatusNames = {
    [AlarmStatus.OK]: "OK",
    [AlarmStatus.Alarming]: "Alarming",
};

export enum EquipmentType {
    Sign = 1,
    Barrier = 2,
}

export const EquipmentTypeNames = {
    [EquipmentType.Sign]: "Sign",
    [EquipmentType.Barrier]: "Barrier",
};