import { CheckIcon, Image, Stack } from "@mantine/core";

import { Sign } from "../../../routes/Pair";
import styles from "./SignItem.module.css";
import { getImageUrlForSign } from "../../../../utils";

type Props = {
  active: boolean;
  handleClick: (sign: Sign | null) => void;
  name: string;
  value?: string;
};

export default function SignItem({ active, handleClick, name, value }: Props) {
  return (
    <Stack
      onClick={() => handleClick(active ? null : { name, value })}
      bg={active ? "rgba(0, 0, 0, 0)" : ""}
      className={styles.sign}
      style={{
        border: `3px solid ${active ? "rgba(0, 0, 0, 0.5)" : "transparent"}`,
      }}
      align="center"
      justify="flex-end"
      pt={8}
      px={8}
      pos="relative"
    >
      {active && (
        <div className={styles.check}>
          <CheckIcon size={16} color="green" />
        </div>
      )}
      <Image fit="contain" mah="80px" src={getImageUrlForSign(value)} />
      <span className={styles.label}>{name}</span>
    </Stack>
  );
}
